<template>
    <div class="wrap ver_wrap" style="overflow: auto">
        <Aside v-if="showAside" @closeAside="showAside = false" v-click-outside="onClickOutside" />
        <header class="header">
            <div class="header_box">
                <div class="logo">
                    <a href="">
                        <img src="/media/img/logo.svg" alt="" />
                    </a>
                </div>
            </div>
        </header>
        <section class="main space_choice">
            <h2>이용하실 스페이스를 선택해주세요.</h2>
            <div class="main_box">
                <ul v-for="(item, index) in spaceList" :key="index" @click="linkPlayer(item)">
                    <li v-if="item.brandImg != null">
                        <img :src="baseImgUrl + item.brandImg.substr(12)" alt="" />
                    </li>
                    <li v-else>
                        <img :src="item.spaceImg" alt="스페이스 이미지" />
                    </li>
                    <li v-html="item.spaceNm" />
                </ul>
            </div>
        </section>
        <notice-modal />
    </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api';
import Aside from '@/layout/aside/Aside.vue';
import vClickOutside from 'v-click-outside';
import VueCookies from 'vue-cookies';
// import router from '@/router';
import axios from '@/service/axios';
import NoticeModalComponent from '@/components/notice/NoticeModalComponent.vue';
export default defineComponent({
  name: 'SpaceChice',
  data () {
    return {
      storeImg: '/media/img/choice_thumnail1.png',
      carImg: '/media/img/choice_thumnail3.png',
      baseImgUrl: 'https://www.aplayz.co.kr/media/upload',
      showAside: false,
      spaceList: []
    };
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  components: {
    Aside,
    'notice-modal': NoticeModalComponent
  },
  methods: {
    async getSpaceList () {
      var userId = VueCookies.get('userId');
      var aToken = VueCookies.get('aToken');
      var headers = { 'X-AUTH-TOKEN': aToken };

      await axios
        .post('/api/profile/subscribeSpaceList', { userId }, { headers })
        .then((res) => {
          if (res.data.resultCd === '0000') {
            this.spaceList = res.data.result;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async linkPlayer (space) {
      VueCookies.set('spaceId', space.spaceId);
      this.$store.commit('setSpaceNm', space.spaceNm);
      this.$VideoPlayer.setVideoEle();
      this.$VideoPlayer.onPlay();
      this.$router
        .push({
          name: 'PlayerView',
          params: { spaceState: 'changeSpace', spaceType: space.spaceType, setIsLoading: true }
        })
        .catch((e) => e);
      // router.push({ name: "PlayerWS", params: { spaceState: 'newSpace' } }).catch(e => e);
    },
    onClickOutside (event) {
      this.showAside = false;
    }
  },
  async created () {
    await this.getSpaceList();
  }
});
</script>

<style scoped>
.space_choice {
  width: 940px;
  height: 100vh;
  margin: 0 auto;
  padding-top: 132px;
  overflow: auto;
}

.space_choice .main_box {
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  flex-wrap: wrap;
}

.space_choice h2 {
  color: #fff;
  font-size: 38px;
  font-weight: 500;
  margin-bottom: 38px;
}

.space_choice ul {
  width: 160px;
  margin-right: 27px;
  margin-bottom: 27px;
  cursor: pointer;
  padding: 0px;
}

/* .space_choice ul:first-child { margin-left:0;} */
.space_choice ul li {
  color: rgba(255, 255, 255, 0.87);
  font-size: 20px;
  font-weight: 400;
  transition: all 0.3s;
  overflow: hidden;
}

.space_choice ul li img {
  width: 160px;
  height: 160px;
  border-radius: 8px;
}

.space_choice ul:hover li:nth-child(1) {
  transform: translateY(-10px);
  box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.84);
}

.space_choice ul li:nth-child(2) {
  margin-top: 10px;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: auto;
}

@media all and (max-width: 1200px) {
  /*main_space_choice*/
  .space_choice {
    width: 90%;
  }

  .space_choice .main_box {
    flex-wrap: wrap;
  }

  .space_choice h2 {
    color: #fff;
    font-size: 38px;
    font-weight: 500;
    margin-bottom: 38px;
  }

  .space_choice ul {
    margin: 10px;
  }
}

@media all and (max-width: 500px) {
  /*space_choice*/
  .space_choice {
    padding-top: 80px;
  }

  .space_choice h2 {
    font-size: 18px;
    text-align: center;
  }

  .space_choice .main_box {
    display: block;
  }

  .space_choice ul {
    width: 100%;
    margin: 0 0 16px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .space_choice ul li {
    font-size: 16px;
  }

  .space_choice ul li img {
    width: 100%;
    width: 60px;
    height: 60px;
  }

  .space_choice ul li:nth-child(2) {
    text-align: center;
    padding-left: 16px;
  }

  .main_quration_box {
    width: 90%;
  }

  .main_quration_box h3 {
    padding: 24px 0px 25px;
    font-size: 14px;
    text-align: center;
  }

  .main_quration_btn p {
    font-size: 14px;
  }
}
</style>

<style src="@/assets/css/content2.css"></style>
<style src="@/assets/css/font.css"></style>
